import styles from "./SubHeader.module.scss";

import { memo } from "react";

const SubHeader = (props: any) => {
  const { children } = props;

  return (
    <>
      <div className={styles.MainInfoBlockHeader}>
        <div className={styles.MainInfoBlockHeaderLinksWrapper}>{children}</div>
      </div>
    </>
  );
};

export default memo(SubHeader);
