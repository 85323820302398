import styles from './Button.module.scss';

import classNames from 'classnames';

import ButtonTypes from 'utils/types';

const Button = (props) => {
  const { icon, type = ButtonTypes.Primary, label, onClick, customStyles } = props;
  return (
    <div
      className={classNames({
        [styles.Button]: true,
      [styles.ButtonPrimary]: type === ButtonTypes.Primary,
        [styles.ButtonSecondary]: type === ButtonTypes.Secondary,
        [styles.ButtonDelete]: type === ButtonTypes.Delete,
        [customStyles]: customStyles
      })}
      onClick={onClick}
    >
      {icon ? icon : null}
      <div
        className={classNames({
          [styles.ButtonLabel]: true,
          [styles.ButtonLabelWithIcon]: !!icon,
        })}
      >
        {label}
      </div>
    </div>
  );
};

export default Button;
