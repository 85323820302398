import ModalWrapper from "admin/modals/ModalWrapper";
import styles from "./BookingCardModal.module.scss";

import Button from "admin/components/Atoms/Button";
import capitalizeFirstLetter from "src/utils/capitalizeFirstLetter";
import moment from "moment";
import "moment/locale/ru";
import { BOOKING_STATUS, BOOKING_STATUS_ID } from "utils/constants";
import { toast } from "react-toastify";

import ButtonTypes from "utils/types";
import { useEffect, useMemo, useState } from "react";
import Input from "admin/components/Atoms/Input";

import { useMutation } from "react-query";
import { basePostRequest } from "utils/api/api";

import {
  questBookingUrl,
  saveClientUrl,
} from "utils/api/requestUrls";

import calculateFinalPrice from "src/utils/calculateFinalPrice";
import CustomSelect from "admin/components/Atoms/CustomSelect";

import * as Sentry from "@sentry/nextjs";

const BookingCardModal = (props) => {
  const {
    activeItem,
    isBookingModalVisible,
    setIsBookingModalVisible,
    handleTransferBookingClick,
    onConfirmClick,
    onDeclineClick,
    blockBookingMutate,
    updatemBookingMutate,
    authorities,
    questPrices,
    handleBookingSubmit,
  } = props;

  const toastStyles: any = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  const countOfPlayersArr = useMemo(() => {
    return questPrices?.map(item => {
      return {
        label: item.countOfPlayers,
        value: item.countOfPlayers,
      }
    })
  }, [questPrices]);

  const [isEditableView, setIsEditableView] = useState(false);
  const [editableData, setEditableData] = useState({ ...activeItem });
  const [countOfPlayersValue, setCountOfPlayersValue] = useState({ value: 2, label: 2 })

  const handleInputChange = (value, fieldName) => {
    if (fieldName === 'countOfPlayers') {
      setEditableData((state) => ({ ...state, [fieldName]: value.value }));
      setCountOfPlayersValue(value);
    } else {
      setEditableData((state) => ({ ...state, [fieldName]: value }));
    }
  };

  const handleInputClientChange = (value, fieldName) => {
    setEditableData((state) => ({ ...state, paidByClient: { ...state.paidByClient, [fieldName]: value } }));
  };

  const finilPrice = useMemo<any>(() => {
    return calculateFinalPrice(
      editableData?.extraPrice,
      questPrices?.find(
        (item) => item.countOfPlayers === Number(editableData.countOfPlayers)
      )?.price
    );
  }, [questPrices, editableData])

  useEffect(() => {
    handleInputChange(finilPrice, 'finalPrice')
  }, [finilPrice])

  const { mutate: saveClientSubmit, isLoading: saveClientLoading } =
    useMutation(() => basePostRequest(saveClientUrl, {
      name: editableData?.paidByClient?.name,
      email: editableData?.paidByClient?.email,
      phoneNumber: editableData?.paidByClient?.phoneNumber,
    }), {
      onSuccess: (res) => {
        const formData: any = {
          clientId: res?.id,
          questEventId: Number(editableData?.id),
          orderStatus: BOOKING_STATUS.WAIT_FOR_APPROVE,
          paymentType: "Cash",
          finalPrice: finilPrice,
          countOfPlayers: editableData?.countOfPlayers,
          paidMoney: 0,
          comment: editableData?.comment,
        };

        if (isEditableView) {
          updatemBookingMutate({
            ...editableData,
            finalPrice: finilPrice,
          })
        } else {
          handleBookingSubmit(formData)
        };
      },
      onError: (err: any) => {
        console.warn("error", err);
        Sentry.captureException(
          new Error(err?.message)
        );
      },
    });

  const errorTransfer = (test) => toast.error(test, toastStyles);
  
  const handleSaveButtonClick = () => {
    if (!!editableData?.paidByClient?.name || !!editableData?.paidByClient?.email || !!editableData?.paidByClient?.phoneNumber) {
      if (!editableData?.paidByClient?.name || !editableData?.paidByClient?.phoneNumber) {
        errorTransfer("Укажите все данные для бронирования")
        Sentry.captureException(
          new Error('Укажите все данные для бронирования')
        );
      } else {
        saveClientSubmit();
      }
    } else {
      updatemBookingMutate({
        ...activeItem,
        eventOrderStatus: {
          status: BOOKING_STATUS.ABLE_TO_ORDER,
          id: BOOKING_STATUS_ID.ABLE_TO_ORDER,
        },
        extraPrice: editableData?.finalPrice
      })
    }
  }

  useEffect(() => {
    setIsEditableView(false);
    setCountOfPlayersValue({ value: activeItem?.countOfPlayers || 2, label: activeItem?.countOfPlayers || 2 })
    setEditableData({ ...activeItem, countOfPlayers: activeItem?.countOfPlayers || 2 })
  }, [activeItem])

  const stateBlock = useMemo(() => {
    return {
      ...editableData,
      eventOrderStatus: {
        status: BOOKING_STATUS.BLOCKED,
        id: BOOKING_STATUS_ID.BLOCKED,
      },
    }
  }, [editableData])

  return (
    <ModalWrapper
      isModalActive={isBookingModalVisible}
      setIsModalActive={setIsBookingModalVisible}
    >
      <div className={styles.BokingCardInfoItemHead}>
        <span>{activeItem?.quest?.name}</span>
      </div>
      <div className={styles.BokingCardInfoItemHead}>
        <span>
          {capitalizeFirstLetter(
            moment(activeItem?.startDateTime)
              .locale("ru")
              .format("dddd / DD MMMM / HH:mm")
          )}
        </span>
      </div>

      <div className={styles.BokingCardInfoTitle}>Данные бронирования</div>
      {!isEditableView && activeItem?.eventOrderStatus?.status !== "ABLE_TO_ORDER" ?
        <div className={styles.BokingCardInfoTable}>
          {activeItem?.paidByClient?.name &&
            <div className={styles.BokingCardInfoItem}>
              <span>Имя: </span>
              <span>{activeItem?.paidByClient?.name}</span>
            </div>
          }
          {activeItem?.paidByClient?.email && (
            <div className={styles.BokingCardInfoItem}>
              <span>Почта:</span>
              <a className={styles.MailField}>{activeItem?.paidByClient?.email}</a>
            </div>
          )}
          {activeItem?.paidByClient?.phoneNumber &&
            <div className={styles.BokingCardInfoItem}>
              <span>Телефон:</span>
              <a>{activeItem?.paidByClient?.phoneNumber}</a>
            </div>
          }
          {activeItem?.countOfPlayers && (
            <div className={styles.BokingCardInfoItem}>
              <span>Кол-во игроков:</span>
              <span>{activeItem?.countOfPlayers} чел.</span>
            </div>
          )}
          {activeItem?.paymentType && (
            <div className={styles.BokingCardInfoItem}>
              <span>Тип оплаты:</span>
              <span>{activeItem?.paymentType?.type === "Cash" ? 'Наличными' : activeItem?.paymentType?.type === "Card" ? 'Картой' : 'Сертификат'}</span>
            </div>
          )}
          {(activeItem?.comment || activeItem?.eventOrderStatus?.status === "BLOCKED") && (
            <div className={styles.BokingCardInfoItem}>
              <span>Комментарий:</span>
              <span>{activeItem?.comment ? activeItem?.comment : 'Нет информации'}</span>
            </div>
          )}
        </div>
        :
        <div className={styles.BokingCardInfoTable}>
          <div className={styles.BokingCardInfoItem}>
            <Input
              placeholder='Имя:'
              name='name'
              value={editableData?.paidByClient?.name || ""}
              onChange={handleInputClientChange}
            />
          </div>
          <div className={styles.BokingCardInfoItem}>
            <Input
              placeholder='Телефон:'
              name='phoneNumber'
              value={editableData?.paidByClient?.phoneNumber || ""}
              onChange={handleInputClientChange}
            />
          </div>
          <div className={styles.BokingCardInfoItem}>
            <Input
              placeholder='Почта:'
              name='email'
              value={editableData?.paidByClient?.email || ''}
              onChange={handleInputClientChange}
            />
          </div>
          <div className={styles.BokingCardInfoItem}>
            <CustomSelect
              placeholder='Кол-во игроков:'
              name='countOfPlayers'
              options={countOfPlayersArr}
              value={countOfPlayersValue}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.BokingCardInfoItem}>
            <Input
              placeholder='Комментарий:'
              name='comment'
              value={editableData?.comment || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.BokingCardInfoItem}>
            <Input
              placeholder='Стоимость игры:'
              name='finalPrice'
              type="number"
              value={editableData?.finalPrice || editableData?.extraPrice}
              onChange={handleInputChange}
            />
          </div>
        </div>
      }

      {!isEditableView && activeItem?.eventOrderStatus?.status !== "ABLE_TO_ORDER" &&
        <div className={styles.BokingCardInfoItemPrice}>
          <span>Стоимость игры: </span>
          <a>{finilPrice} рублей</a>
        </div>
      }

      {(activeItem?.eventOrderStatus?.status !== "ABLE_TO_ORDER" && activeItem?.eventOrderStatus?.status !== "BLOCKED" && authorities?.some(item => item.authority === 'ROLE_ADMIN' || item.authority === 'ROLE_MANAGER')) &&
        <div className={styles.ButtonsWrapper}>
          {activeItem?.eventOrderStatus?.status !== "ORDERED" && !isEditableView && (
            <Button
              label="Подтвердить"
              type={ButtonTypes.Primary}
              onClick={() => onConfirmClick(finilPrice)}
              customStyles={
                activeItem?.eventOrderStatus?.status !== "ORDERED"
                  ? styles.ButtonStyle
                  : styles.ButtonStyleSecond
              }
            />
          )}

          {!isEditableView &&
            <Button
              label="Отменить"
              type={ButtonTypes.Delete}
              onClick={onDeclineClick}
              customStyles={
                activeItem?.eventOrderStatus?.status !== "ORDERED"
                  ? styles.ButtonStyle
                  : styles.ButtonStyleSecond
              }
            />
          }

          {!isEditableView && (
            <Button
              label="Перенести"
              type={ButtonTypes.Secondary}
              onClick={() => handleTransferBookingClick(activeItem?.id)}
              customStyles={
                activeItem?.eventOrderStatus?.status !== "ORDERED"
                  ? styles.ButtonStyle
                  : styles.ButtonStyleSecond
              }
            />
          )}
        </div>
      }

      {(activeItem?.eventOrderStatus?.status === "ABLE_TO_ORDER" || activeItem?.eventOrderStatus?.status === "BLOCKED") && authorities?.some(item => item.authority === 'ROLE_ADMIN' || item.authority === 'ROLE_MANAGER') &&
        <div className={styles.ButtonsWrapper}>
          {activeItem?.eventOrderStatus?.status !== "ORDERED" && !isEditableView && (
            <Button
              label={activeItem?.eventOrderStatus?.status === "ABLE_TO_ORDER" ? "Заглушка" : 'Снять заглушку'}
              type={ButtonTypes.Secondary}
              onClick={activeItem?.eventOrderStatus?.status === "ABLE_TO_ORDER" ? () => blockBookingMutate(stateBlock) : onDeclineClick}
              customStyles={styles.ButtonZaglushka}
            />
          )}
        </div>
      }

      {activeItem?.eventOrderStatus?.status !== "BLOCKED" && authorities?.some(item => item.authority === 'ROLE_ADMIN' || item.authority === 'ROLE_MANAGER') &&
        <div className={styles.ButtonsWrapperSecond}>
          {(isEditableView || activeItem?.eventOrderStatus?.status === "ABLE_TO_ORDER") && (
            <Button
              label="Сохранить"
              type={ButtonTypes.Primary}
              onClick={handleSaveButtonClick}
              customStyles={!isEditableView ? styles.ButtonEdit : styles.ButtonStyleSecond}
            />
          )}

          {activeItem?.eventOrderStatus?.status !== "ABLE_TO_ORDER" &&
            <Button
              label={isEditableView ? "Отменить" : "Редактировать бронь"}
              type={ButtonTypes.Secondary}
              onClick={() => setIsEditableView(prevState => !prevState)}
              customStyles={!isEditableView ? styles.ButtonEdit : styles.ButtonStyleSecond}
            />
          }
        </div>
      }
    </ModalWrapper>
  );
};

export default BookingCardModal;
