import ModalWrapper from "admin/modals/ModalWrapper";
import styles from "./BookingCardModal.module.scss";

import capitalizeFirstLetter from "src/utils/capitalizeFirstLetter";
import moment from "moment";
import "moment/locale/ru";

const BookingCardModal = (props) => {
  const {
    activeItem,
    isBookingModalVisible,
    setIsBookingModalVisible,
  } = props;

  return (
    <ModalWrapper
      isModalActive={isBookingModalVisible}
      setIsModalActive={setIsBookingModalVisible}
    >
      <div className={styles.BokingCardInfoItemHead}>
        <span>{activeItem?.quest?.name}</span>
      </div>
      <div className={styles.BokingCardInfoItemHead}>
        <span>
          {capitalizeFirstLetter(
            moment(activeItem?.startDateTime)
              .locale("ru")
              .format("dddd / DD MMMM / HH:mm")
          )}
        </span>
      </div>

      <div className={styles.BokingCardInfoTitle}>Данные бронирования</div>
      <div className={styles.BokingCardInfoTable}>
        <div className={styles.BokingCardInfoItem}>
          <span>Имя: </span>
          <span>{activeItem?.paidByClient?.name ? activeItem?.paidByClient?.name : 'Нет информации'}</span>
        </div>
        <div className={styles.BokingCardInfoItem}>
          <span>Почта:</span>
          <a className={styles.MailField}>{activeItem?.paidByClient?.email ? activeItem?.paidByClient?.email : 'Нет информации'}</a>
        </div>
        <div className={styles.BokingCardInfoItem}>
          <span>Телефон:</span>
          <a>{activeItem?.paidByClient?.phoneNumber ? activeItem?.paidByClient?.phoneNumber : 'Нет информации'}</a>
        </div>

        <div className={styles.BokingCardInfoItem}>
          <span>Кол-во игроков:</span>
          <span>{activeItem?.countOfPlayers ? activeItem?.countOfPlayers + "чел." : 'Нет информации'}</span>
        </div>
        {activeItem?.paymentType && (
          <div className={styles.BokingCardInfoItem}>
            <span>Тип оплаты:</span>
            <span>{activeItem?.paymentType?.type === "Cash" ? 'Наличными' : activeItem?.paymentType?.type === "Card" ? 'Картой' : 'Сертификат'}</span>
          </div>
        )}
        <div className={styles.BokingCardInfoItem}>
          <span>Комментарий:</span>
          <span>{activeItem?.comment ? activeItem?.comment : 'Нет информации'}</span>
        </div>
      </div>

      {activeItem?.eventOrderStatus?.status !== "ABLE_TO_ORDER" &&
        <div className={styles.BokingCardInfoItemPrice}>
          <span>Стоимость игры: </span>
          <a>{activeItem?.finalPrice} рублей</a>
        </div>
      }
    </ModalWrapper>
  );
};

export default BookingCardModal;
