import styles from './ModalWrapper.module.scss';
import classNames from 'classnames';
//@ts-ignore
import CrossIcon from 'public/icons/crossAdmin.svg';

const ModalWrapper = ({ children, isModalActive, setIsModalActive }) => {
  return (
    <div
      className={classNames({
        [styles.ModalWrapper]: true,
        [styles.ModalWrapperActive]: isModalActive,
      })}
    >
      <div className={styles.ModalCard}>
        <CrossIcon className={styles.ModalCloseButton} onClick={() => setIsModalActive(false)}/>
        {children}
      </div>
    </div>
  );
};

export default ModalWrapper;
