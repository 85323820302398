const baseUrl = `${process.env.API_HOST}${process.env.API_VERSION}`;

export const deletePriceForPlayerURL = (id: any) => `${baseUrl}/quests/prices/${id}`;
export const deleteNewsURL = (id: any) => `${baseUrl}/news/${id}`;
export const updateNewsURL = (id: any) => `${baseUrl}/news/${id}`;
export const updatequestInfoURL = (id: any) => `${baseUrl}/quests/${id}`;
export const createNewsURL = `${baseUrl}/news`;
export const getNewsURL = `${baseUrl}/news/page`;
export const reassignBookingUrl = (questEventId: any, targetEventId: any) => `${baseUrl}/quests/events/${questEventId}/reassign?targetEventId=${targetEventId}`;
export const changeStatusBookingUrl = (questEventId: any) => `${baseUrl}/quests/events/${questEventId}`

export const adminCreateUser = `${baseUrl}/users`
export const adminDeleteUser = (id: any) => `${baseUrl}/users/${id}`
