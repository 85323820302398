import React, { memo } from "react";
import Head from "next/head";

import QuizListContainer from "containers/QuizListContainer";

import {
  quizListUrl,
  genresUrl,
  quizListRaitingUrl,
} from "utils/api/requestUrls";

import HomeAdmin from "admin/pages/index";

const Home = (props: any) => {
  return (
    <>
      <Head>
        <title>
          Квесты в Минске | Невероятные квесты в реальности Old mouse
        </title>

        <meta
          name="description"
          content="Заказать все квесты в Минске: квест-комнаты, квесты с актёрами, лучшие квесты в реальности от Old mouse ⚡ Квесты на праздники и корпоративы. Цены, отзывы, расписание и бронирование онлайн на сайте Questminsk.by"
        />
      </Head>
      {!props?.isAdminPage && <QuizListContainer {...props} />}
      {props?.isAdminPage && <HomeAdmin />}
    </>
  );
};

export const getServerSideProps = async (context: any) => {
  const { req, res } = context;
  const isAdminPage = req.headers.host.split(".")[0] === "om-admin";

  const questListResponse = await fetch(quizListUrl(null, null));
  const questListFirstData = await questListResponse.json();

  const genresResponse = await fetch(genresUrl);
  const genresFirstData = await genresResponse.json();

  const raitingResponse = await fetch(quizListRaitingUrl);
  const raitingFirstData = await raitingResponse.json();

  if (req.headers.host.match(/^www/) !== null) {
    res.writeHead(301, {
      location: "https://" + req.headers.host.replace(/^www./, "") + req.url,
    });
    res.end();
  }

  const shuffledArr = questListFirstData?.content.sort(function () {
    return Math.random() - 0.5;
  });

  return {
    props: {
      questListFirstData: shuffledArr,
      genresFirstData,
      raitingFirstData,
      isAdminPage: isAdminPage,
    },
  };
};

export default memo(Home);
