import classNames from 'classnames';
import styles from './Input.module.scss';

const Input = ({
  placeholder,
  value,
  onChange,
  name,
  type = 'text',
  InputWithOutMargins,
  isErrorField,
  disabled,
  onFocus,
  onBlur,
  searcheble,
  onCrossClick,
  isSerchActive,
}: any) => {
  return (
    <p className={styles.InputWrapper}>
      <input
        className={classNames({
          [styles.Input]: true,
          [styles.InputWithOutMargins]: InputWithOutMargins,
          [styles.InputErrorField]: isErrorField,
          [styles.InputDisabled]: !!disabled,
        })}
        placeholder=''
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value, name)}
        name={name}
        onFocus={onFocus}
        onBlur={onBlur}
        type={type}
      />
      <label
        className={classNames({
          [styles.Label]: true,
          [styles.LabelWithOutMargins]: InputWithOutMargins,
        })}
      >
        {placeholder}
      </label>
    </p>
  );
};

export default Input;
