import { useEffect, useState, useMemo } from "react";

import styles from "./BookingListContainer.module.scss";

import classNames from "classnames";
import moment from "moment";
import { useMutation, useQuery } from "react-query";
import { toast, ToastContainer } from "react-toastify";
import { Circles } from "react-loader-spinner";

import BookingCardModal from "admin/modals/BookingCardModal";
import BookingCardAllQuestsModal from "admin/modals/BookingCardAllQuestsModal";
import CustomSelect from "../Atoms/CustomSelect";

import "moment/locale/ru";

import { BOOKING_STATUS, BOOKING_STATUS_ID } from "utils/constants";
import { reassignBookingUrl, changeStatusBookingUrl } from "admin/utils/api/requestUrls";
import { basePutRequest } from "admin/utils/api/api";
import { baseGetRequest, basePostRequest } from "utils/api/api";
import { singleQuestUrl } from "utils/api/requestUrls";
import getUser from 'admin/src/utils/getUser';
import capitalizeFirstLetter from "src/utils/capitalizeFirstLetter";

import * as Sentry from "@sentry/nextjs";

import {
  questBookingUrl,
  saveClientUrl,
} from "utils/api/requestUrls";

const BookingListContainer = (props) => {
  const {
    questsArr,
    activeId,
    setActiveId,
    questData,
    isGetscheduleBodyLoading,
    isAllQuestsScreen,
    isGetAllQuestsLoading,
    allQuestData,
    getscheduleBody,
    getShechlduleAllQuests,
  } = props;

  const [authorities, setAuthorities] = useState<any>([]);
  const [activeDate, setActiveDate] = useState(null);
  const [datesArr, setDatesArr] = useState<any>([]);
  const [mobileActiveValue, setMobileActiveValue] = useState<any>(null)
  const [mobileActiveDateValue, setMobileActiveDateValue] = useState<any>(null)

  useEffect(() => {
    getUser().then(
      (user) => {
        const {
          authorities = [],
        } = user;
        setAuthorities(authorities);
      }
    );
  }, []);

  const { isLoading: isQuestByIdLoading, data: questById, refetch: refetchQuestById } = useQuery(
    "getQuestById",
    () => baseGetRequest(singleQuestUrl(activeId))
  );

  useEffect(() => {
    refetchQuestById()
  }, [activeId])

  const datesOptions = useMemo(() => {
    return datesArr.map(item => {
      return {
        label: item,
        value: item,
      }
    })
  }, [datesArr])

  useEffect(() => {
    if (!mobileActiveValue) {
      setMobileActiveValue(questsArr?.find(item => item?.value === 1));
    };
  }, [questsArr])

  useEffect(() => {
    if (!mobileActiveDateValue) {
      setMobileActiveDateValue(datesOptions?.find(item => item?.value === activeDate));
    };
  }, [activeDate, datesOptions])

  useEffect(() => {
    if (mobileActiveDateValue) {
      setActiveDate(mobileActiveDateValue?.value)
    }
  }, [mobileActiveDateValue])

  useEffect(() => {
    if (mobileActiveValue) {
      setActiveId(mobileActiveValue?.value)
    };
  }, [mobileActiveValue])

  const toastStyles: any = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  function generateDateArray() {
    const today = new Date();
    const dates: any = [];

    for (let i = 0; i < 30; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      const formattedDate = `${String(date.getDate()).padStart(
        2,
        "0"
      )}-${String(date.getMonth() + 1).padStart(2, "0")}-${date.getFullYear()}`;
      dates.push(formattedDate);
    }

    setActiveDate(dates[0]);
    setDatesArr(dates);
  }

  useEffect(() => {
    generateDateArray();
  }, []);

  const [isBookingModalVisible, setIsBookingModalVisible] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);

  const [isTransferBookingClicked, setIsTransferBookingClicked] =
    useState(false);
  const [prevTransferBookingId, setPrevTransferBookingId] = useState(null);

  const errorTransfer = () => toast.error("Это место уже занято.", toastStyles);

  const handleItemClick = (concreteTime) => {
    setActiveItem(concreteTime);
    setIsBookingModalVisible(true);
  };

  const handleDeclineTransferClick = () => {
    setIsTransferBookingClicked(false);
    setPrevTransferBookingId(null);
  };

  const handleTransferBookingClick = (currentId: any) => {
    setPrevTransferBookingId(currentId);
    setIsTransferBookingClicked(true);
    setIsBookingModalVisible(false);
  };

  const handleCancelTransferClick = () => {
    setIsTransferBookingClicked(false);
    setPrevTransferBookingId(null);
  };

  const {
    mutate: reassignBookingMutate,
    isLoading: isReassignBookingMutateLoading,
  } = useMutation(
    (targetEventId: any) =>
      basePostRequest(
        reassignBookingUrl(prevTransferBookingId, targetEventId),
        null
      ),
    {
      onSuccess: (res) => {
        handleDeclineTransferClick();
        getscheduleBody(activeId);
      },
      onError: (err: any) => {
        console.log("err", err);
        Sentry.captureException(
          new Error(err?.message)
        );
      },
    }
  );

  const {
    mutate: confirmBookingMutate,
    isLoading: isConfirmBookingMutateLoading,
  } = useMutation(
    (finilPrice) =>
      basePutRequest(
        changeStatusBookingUrl(activeItem?.id),
        {
          ...activeItem,
          eventOrderStatus: {
            status: BOOKING_STATUS.ORDERED,
            id: BOOKING_STATUS_ID.ORDERED,
          },
          finalPrice: finilPrice,
          extraPrice: activeItem.extraPrice.toFixed(2)
        }
      ),
    {
      onSuccess: (res) => {
        if (isAllQuestsScreen) {
          getShechlduleAllQuests(true);
        } else {
          getscheduleBody(activeId);
        }
        setIsBookingModalVisible(false);
      },
      onError: (err: any) => {
        console.log("err", err);
        Sentry.captureException(
          new Error(err?.message)
        );
      },
    }
  );

  const {
    mutate: declineBookingMutate,
    isLoading: isDeclineBookingMutateLoading,
  } = useMutation(
    () =>
      basePutRequest(
        changeStatusBookingUrl(activeItem?.id),
        {
          ...activeItem,
          comment: null,
          countOfPlayers: null,
          eventOrderStatus: {
            status: BOOKING_STATUS.ABLE_TO_ORDER,
            id: BOOKING_STATUS_ID.ABLE_TO_ORDER,
          },
          finalPrice: 0,
          extraPrice: activeItem.extraPrice.toFixed(2),
          paidByClient: null,
          paymentType: null,

        }
      ),
    {
      onSuccess: (res) => {
        if (isAllQuestsScreen) {
          getShechlduleAllQuests(true);
        } else {
          getscheduleBody(activeId);
        }
        setIsBookingModalVisible(false);
      },
      onError: (err: any) => {
        console.log("err", err);
        Sentry.captureException(
          new Error(err?.message)
        );
      },
    }
  );

  const {
    mutate: blockBookingMutate,
    isLoading: isBlockBookingMutateLoading,
  } = useMutation(
    (state) =>
      basePutRequest(
        changeStatusBookingUrl(activeItem?.id),
        state
      ),
    {
      onSuccess: (res) => {
        if (isAllQuestsScreen) {
          getShechlduleAllQuests(true);
        } else {
          getscheduleBody(activeId);
        }
        setIsBookingModalVisible(false);
      },
      onError: (err: any) => {
        console.log("err", err);
        Sentry.captureException(
          new Error(err?.message)
        );
      },
    }
  );

  const {
    mutate: updateBookingMutate,
    isLoading: isUpdateBookingMutateLoading,
  } = useMutation(
    (state) =>
      basePutRequest(
        changeStatusBookingUrl(activeItem?.id),
        state
      ),
    {
      onSuccess: (res) => {
        if (isAllQuestsScreen) {
          getShechlduleAllQuests(true);
        } else {
          getscheduleBody(activeId);
        }
        setIsBookingModalVisible(false);
      },
      onError: (err: any) => {
        console.log("err", err);
        Sentry.captureException(
          new Error(err?.message)
        );
      },
    }
  );

  const {
    mutate: updatemBookingMutate,
    isLoading: isUpdatemBookingMutateLoading,
  } = useMutation(
    (state) =>
      basePutRequest(
        changeStatusBookingUrl(activeItem?.id),
        state
      ),
    {
      onSuccess: (res) => {
        if (isAllQuestsScreen) {
          getShechlduleAllQuests(true);
        } else {
          getscheduleBody(activeId);
        }
        setIsBookingModalVisible(false);
      },
      onError: (err: any) => {
        console.log("err", err);
        Sentry.captureException(
          new Error(err?.message)
        );
      },
    }
  );

  const { mutate: handleBookingSubmit, isLoading: isBookingSubmitLoading } =
  useMutation((formData: any) => basePostRequest(questBookingUrl(activeItem?.id), formData), {
    onSuccess: (res) => {
      Sentry.captureException(
        new Error('gooood')
      );
      if (isAllQuestsScreen) {
        getShechlduleAllQuests(true);
      } else {
        getscheduleBody(activeId);
      }
      setIsBookingModalVisible(false);
    },
    onError: (err: any) => {
      console.warn("error", err);
      Sentry.captureException(
        new Error(err?.message)
      );
    },
  });


  const handleTransferClick = (item: any) => {
    if (
      item.eventOrderStatus.status === BOOKING_STATUS.ABLE_TO_ORDER ||
      item.eventOrderStatus.status === BOOKING_STATUS.BLOCKED
    ) {
      reassignBookingMutate(item.id);
    } else {
      errorTransfer();
    }
  };

  const filteredWithSumm = allQuestData?.sort((a, b) => a.questId - b.questId)?.map((item, i) => item?.data?.filter((event) => moment(event?.[0]?.startDateTime).format("DD-MM-yyyy") === activeDate))
  const summByDay = filteredWithSumm?.map(item => item?.map(time => time?.reduce((acc, curr) => acc + curr?.finalPrice, 0)));
  const finalSummByDay = summByDay?.reduce((acc, curr) => acc + curr?.[0], 0);

  if (!!isAllQuestsScreen) {
    return (
      <>
        {isGetAllQuestsLoading && (
          <div className={styles.Loader}>
            <Circles
              height="80"
              width="80"
              color="red"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}

        {authorities?.some(item => item.authority === 'ROLE_ADMIN') &&
          <div className={styles.PotencialMoney}>
            <div className={styles.PotencialMoneyText}>Потенциальный доход за день: <span className={styles.PotencialMoneyPrice}> {finalSummByDay} рублей</span></div>
          </div>
        }

        <div className={styles.BookingListContainer}>
          {questsArr?.length > 1 && (
            <div className={styles.QuestNameItemsWrapper}>
              {datesArr?.map((item, i) => (
                <div
                  className={classNames({
                    [styles.QuestNameItem]: true,
                    [styles.QuestNameItemActive]: item == activeDate,
                  })}
                  onClick={() => setActiveDate(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          )}

          {questsArr?.length > 1 && (
            <div className={styles.QuestNameItemsWrapperMobile}>
              <CustomSelect
                placeholder='Дата:'
                name='roles'
                options={datesOptions}
                value={mobileActiveDateValue}
                onChange={setMobileActiveDateValue}
              />
            </div>
          )}

          <div>
            {allQuestData
              ?.sort((a, b) => a.questId - b.questId)
              ?.map((item, i) => {
                return (
                  <div
                    className={classNames({
                      [styles.BookingSchedule]: true,
                      [styles.BookingScheduleIsDashed]:
                        i < allQuestData.length - 1,
                    })}
                    key={item.id}
                  >
                    <div className={styles.BookingDay}>
                      <div>
                        {questsArr?.map((quest) => {
                          if (quest.value === item.questId) {
                            return quest.label;
                          }
                        })}
                      </div>
                    </div>

                    <div className={styles.BookingListWrapper}>
                      {item?.data?.map((event) => {
                        if (
                          moment(event?.[0]?.startDateTime).format(
                            "DD-MM-yyyy"
                          ) === activeDate
                        ) {
                          return event.map((concreteTime) => {
                            const { startDateTime, eventOrderStatus, id } =
                              concreteTime;

                            return (
                              <div
                                key={id}
                                className={styles.BookingItemWrapper}
                                onClick={() =>
                                  isTransferBookingClicked
                                    ? handleTransferClick(concreteTime)
                                    : handleItemClick(concreteTime)
                                }
                              >
                                <div
                                  className={classNames({
                                    [styles.BookingItem]: true,
                                    [styles.BookingItemOrdered]:
                                      eventOrderStatus.status ===
                                      BOOKING_STATUS.WAIT_FOR_APPROVE,
                                    [styles.BookingItemAccepted]:
                                      eventOrderStatus.status ===
                                      BOOKING_STATUS.ORDERED,
                                    [styles.BookingItemFixed]:
                                      eventOrderStatus.status ===
                                      BOOKING_STATUS.BLOCKED,
                                    [styles.BookingItemCanceled]:
                                      eventOrderStatus.status ===
                                      BOOKING_STATUS.CANCELLED,
                                  })}
                                >
                                  <div className={styles.BookingItemTime}>
                                    {moment(startDateTime).format("HH:mm")}
                                  </div>
                                </div>
                                <div className={styles.BookingItemPrice}>
                                  {eventOrderStatus.status ===
                                    BOOKING_STATUS.ABLE_TO_ORDER && concreteTime.extraPrice + " рублей"}
                                  {eventOrderStatus.status ===
                                    BOOKING_STATUS.CANCELLED && concreteTime.extraPrice + " рублей"}
                                  {eventOrderStatus.status ===
                                    BOOKING_STATUS.WAIT_FOR_APPROVE &&
                                    concreteTime.extraPrice + " рублей"}
                                  {eventOrderStatus.status ===
                                    BOOKING_STATUS.ORDERED &&
                                    concreteTime.extraPrice + " рублей"}
                                  {eventOrderStatus.status ===
                                    BOOKING_STATUS.BLOCKED && "Заглушка"}
                                </div>
                              </div>
                            );
                          });
                        }
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
          <BookingCardAllQuestsModal
            activeItem={activeItem}
            isBookingModalVisible={isBookingModalVisible}
            setIsBookingModalVisible={setIsBookingModalVisible}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <ToastContainer />

      {isTransferBookingClicked &&
        <div className={styles.TransferBookingWrapper}>
   

          <div
            className={'w-full bg-red-300 p-2 rounded-3 font-bold text-center cursor-pointer hover:opacity-90'}
            onClick={handleCancelTransferClick}
          >
            Отменить перенос
          </div>
        </div>
      }
      {isGetscheduleBodyLoading && (
        <div className={styles.Loader}>
          <Circles
            height="80"
            width="80"
            color="red"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      <div className={styles.BookingListContainer}>
        {questsArr?.length > 1 && (
          <div className={styles.QuestNameItemsWrapper}>
            {questsArr?.map((item, i) => (
              <div
                className={classNames({
                  [styles.QuestNameItem]: true,
                  [styles.QuestNameItemActive]: item.value == activeId,
                })}
                onClick={() => setActiveId(item.value)}
              >
                {item.label}
              </div>
            ))}
          </div>
        )}
        {questsArr?.length > 1 && (
          <div className={styles.QuestNameItemsWrapperMobile}>
            <CustomSelect
              placeholder='Квест:'
              name='roles'
              options={questsArr}
              value={mobileActiveValue}
              onChange={setMobileActiveValue}
            />
          </div>
        )}
        <div>
          {questData.map((item, i) => (
            <div
              className={classNames({
                [styles.BookingSchedule]: true,
                [styles.BookingScheduleIsDashed]: i < questData.length - 1,
              })}
              key={item.id}
            >
              <div className={styles.BookingDay}>
                <div>
                  {capitalizeFirstLetter(
                    moment(item?.[0]?.startDateTime)
                      .locale("ru")
                      .format("DD MMMM")
                  )}
                </div>
                <div
                  className={classNames({
                    [styles.BookingDayDay]: true,
                    [styles.BookingDayDayWeekends]:
                      capitalizeFirstLetter(
                        moment(item?.[0]?.startDateTime)
                          .locale("ru")
                          .format("dddd")
                      ) === "Суббота" ||
                      capitalizeFirstLetter(
                        moment(item?.[0]?.startDateTime)
                          .locale("ru")
                          .format("dddd")
                      ) === "Воскресенье",
                  })}
                >
                  {capitalizeFirstLetter(
                    moment(item?.[0]?.startDateTime).locale("ru").format("dddd")
                  )}
                </div>
              </div>

              <div className={styles.BookingListWrapper}>
                {item?.map((concreteTime) => {
                  const { startDateTime, eventOrderStatus, id } = concreteTime;

                  return (
                    <div
                      key={id}
                      className={styles.BookingItemWrapper}
                      onClick={() => {
                        if (
                          eventOrderStatus.status !== BOOKING_STATUS.CANCELLED
                        ) {
                          isTransferBookingClicked
                            ? handleTransferClick(concreteTime)
                            : handleItemClick(concreteTime);
                        } else {
                        }
                      }}
                    >
                      <div
                        className={classNames({
                          [styles.BookingItem]: true,
                          [styles.BookingItemOrdered]:
                            eventOrderStatus.status ===
                            BOOKING_STATUS.WAIT_FOR_APPROVE,
                          [styles.BookingItemAccepted]:
                            eventOrderStatus.status === BOOKING_STATUS.ORDERED,
                          [styles.BookingItemFixed]:
                            eventOrderStatus.status === BOOKING_STATUS.BLOCKED,
                          [styles.BookingItemCanceled]:
                            eventOrderStatus.status ===
                            BOOKING_STATUS.CANCELLED,
                        })}
                      >
                        <div className={styles.BookingItemTime}>
                          {moment(startDateTime).format("HH:mm")}
                        </div>
                      </div>
                      <div className={styles.BookingItemPrice}>
                        {eventOrderStatus.status ===
                          BOOKING_STATUS.ABLE_TO_ORDER && concreteTime.extraPrice + " рублей"}
                        {eventOrderStatus.status === BOOKING_STATUS.CANCELLED &&
                          concreteTime.extraPrice + " рублей"}
                        {eventOrderStatus.status ===
                          BOOKING_STATUS.WAIT_FOR_APPROVE &&
                          concreteTime.extraPrice + " рублей"}
                        {eventOrderStatus.status === BOOKING_STATUS.ORDERED &&
                          concreteTime.extraPrice + " рублей"}
                        {eventOrderStatus.status === BOOKING_STATUS.BLOCKED &&
                          "Заглушка"}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
        <BookingCardModal
          activeItem={activeItem}
          isBookingModalVisible={isBookingModalVisible}
          setIsBookingModalVisible={setIsBookingModalVisible}
          handleTransferBookingClick={handleTransferBookingClick}
          onConfirmClick={confirmBookingMutate}
          onDeclineClick={declineBookingMutate}
          blockBookingMutate={blockBookingMutate}
          updateBookingMutate={updateBookingMutate}
          updatemBookingMutate={updatemBookingMutate}
          authorities={authorities}
          questPrices={questById?.questPrices}
          handleBookingSubmit={handleBookingSubmit}
        />
      </div>
    </>
  );
};

export default BookingListContainer;
