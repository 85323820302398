import React, { memo, useMemo } from "react";
import Head from "next/head";

import { useEffect, useState } from "react";

import { quizListUrl, questEventsListUrl } from "utils/api/requestUrls";
import BookingListContainer from "admin/components/BookingListContainer";
import _ from "lodash";
import { useMutation, useQuery } from "react-query";

import { baseGetRequest } from "utils/api/api";
import PageWrapperAdmin from "admin/components/Templates/PageWrapperAdmin";
import SubHeader from "admin/components/Templates/SubHeader";

import Button from "admin/components/Atoms/Button";

export default memo(function Home(props) {
  const [activeId, setActiveId] = useState(1);
  const [questData, setQuestData] = useState([]);
  const [allQuestData, setAllQuestData] = useState<any>([]);
  const [isAllQuestsScreen, seIsAllQuestsScreen] = useState<any>(false);

  const { isLoading: isQuestListLoading, data: questList } = useQuery(
    "getQuestList",
    () => baseGetRequest(quizListUrl(null, null))
  );

  const questsArr = useMemo(
    () =>
      questList?.content
        ?.filter((quest) => !quest?.progressStatus)
        ?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        }),
    [questList]
  );

  const getShechlduleAllQuests = (isShouldReset) => {
    if (isShouldReset) {
      setAllQuestData([]);
    };

    if (!allQuestData.length || isShouldReset) {
      questsArr?.map((item: any) => {
        getAllQuests(item.value);
      });
    }

    seIsAllQuestsScreen(true);
  };

  const { mutate: getscheduleBody, isLoading: isGetscheduleBodyLoading } =
    useMutation((id: any) => baseGetRequest(questEventsListUrl(id)), {
      onSuccess: (res) => {
        setQuestData(Object.values(_.groupBy(res.content, "startDate")));
      },
      onError: (err: any) => { },
    });

  const { mutate: getAllQuests, isLoading: isGetAllQuestsLoading } =
    useMutation((id: any) => baseGetRequest(questEventsListUrl(id)), {
      onSuccess: (res, reqId) => {
        setAllQuestData((prevState: any) => {
          return [
            ...prevState,
            {
              questId: reqId,
              data: Object.values(_.groupBy(res.content, "startDate")),
            },
          ];
        });
      },
      onError: (err: any) => { },
    });

  useEffect(() => {
    getscheduleBody(activeId);
  }, [activeId]);

  return (
    <>
      <Head>
        <title>Админ | OldMouse</title>

        <meta name="description" content="Админ | Old mouse" />
      </Head>

      <PageWrapperAdmin>
        <SubHeader
        >
          <Button
            label={
              !isAllQuestsScreen
                ? "Отображение по дням"
                : "Классическое отображение"
            }
            onClick={
              !isAllQuestsScreen
                ? () => getShechlduleAllQuests(false)
                : () => seIsAllQuestsScreen(false)
            }
          />
        </SubHeader>

        <BookingListContainer
          {...props}
          questsArr={questsArr}
          activeId={activeId}
          setActiveId={setActiveId}
          getscheduleBody={getscheduleBody}
          questData={questData}
          isGetscheduleBodyLoading={isGetscheduleBodyLoading}
          isAllQuestsScreen={isAllQuestsScreen}
          isGetAllQuestsLoading={isGetAllQuestsLoading}
          allQuestData={allQuestData}
          getShechlduleAllQuests={getShechlduleAllQuests}
          setAllQuestData={setAllQuestData}
        />
      </PageWrapperAdmin>
    </>
  );
});
