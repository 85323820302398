import classNames from 'classnames';
import styles from './CustomSelect.module.scss';
import Select from 'react-select';

const CustomSelect = ({
  placeholder,
  options,
  value,
  onChange,
  disabled,
  name,
  type = 'text',
  InputWithOutMargins,
  isErrorField,
  multiple = false,
}: any) => {
  return (
    <div className={styles.Select}>
      <div className={styles.SelectPlaceholder}>{placeholder}</div>
      <Select
        options={options}
        classNamePrefix='react-select'
        isDisabled={disabled}
        value={value}
        isMulti={multiple}
        onChange={(value) => onChange(value, name)}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: disabled ? '#E1E2EF' : state.isFocused ? '#797D93' : '#797D93',
            background: disabled ? '#FFF' : '#FFF',
          }),
        }}
      />
    </div>
  );
};

export default CustomSelect;
