import React, { memo } from "react";
import styles from './PageWrapperAdmin.module.scss';

const PageWrapperAdmin = ({ children }) => {
  return (
    <div className={styles.Wrapper}>
      <div className="flex flex-shrink-0 flex-col w-full  mb-4">
        {children}
      </div>
    </div>
  );
};

export default memo(PageWrapperAdmin);